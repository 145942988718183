import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Injectable, computed, inject, signal } from "@angular/core";
import { JwtHelperService } from "@auth0/angular-jwt";
import { Observable, catchError, map, of, throwError } from "rxjs";
import {
  AuthStatus,
  TokenChekResponse,
  User,
  UserAuthResponse,
  UserToken,
} from "src/app/shared/model/usuarios.model";
import { environment } from "src/environments/environment";

@Injectable({
  providedIn: "root",
})
export class UsuarioService {
  private jwtHelper = inject(JwtHelperService);
  private http = inject(HttpClient);

  private _user = signal<UserToken | null>(null);
  private _authStatus = signal<AuthStatus>(AuthStatus.checking);

  //exponer fuera de la clase
  public user = computed(() => this._user());
  public authStatus = computed(() => this._authStatus());

  constructor() {
    this.checkAuthStatus().subscribe();
  }

  checkAuthStatus(): Observable<boolean> {
    const url = `${environment.url}auth/TokenCheck`;
    const token = localStorage.getItem("token");

    if (!token) {
      this.logout();
      return of(false);
    }

    const headers = new HttpHeaders().set("Authorization", `Bearer ${token}`);

    return this.http.get<TokenChekResponse>(url, { headers }).pipe(
      map((resp) => {
        if (resp.logged) {
          this.setAuthentication(resp.token);
          return true;
        } else {
          this.logout();
          return false;
        }
      }),
      catchError((err) => {
        //console.log("checAuthStatus:", err);
        this._authStatus.set(AuthStatus.notAuthenticated);
        return of(false);
      })
    );
  }

  login(us: User){
    const url = `${environment.url}auth/login`;

    return this.http.post<UserAuthResponse>(url, us)
    .pipe(
      map((resp) => this.setAuthentication(resp.token)),
      catchError((err) => throwError(() => err))
    );
  }

  private setAuthentication(token: string): boolean {
    const decode = this.tokenDecode(token);
    const tok = this.setUsuario(decode);

    this._user.set(tok);
    this._authStatus.set(AuthStatus.authenticated);
    localStorage.setItem("token", token);
    return true;
  }

  private setUsuario(decode: UserToken): UserToken {
    const token_us = {
      usuario: decode.usuario,
      id: decode.id,
      sucursales: decode.sucursales,
    };
    // this.sucursal_signal.set(decode.sucursales);

    return token_us;
  }

  private tokenDecode(token: string) {
    const decode = this.jwtHelper.decodeToken(token);

    decode.sucursales = JSON.parse(decode.sucursales);

    return decode;
  }

  public logout() {
    localStorage.removeItem("token");
    localStorage.removeItem("url");
    this._user.set(null);
    this._authStatus.set(AuthStatus.notAuthenticated);
  }
}
