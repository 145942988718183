import { inject } from "@angular/core";
import { CanActivateFn, Router } from "@angular/router";
import { UsuarioService } from "src/app/services/usuario.service";
import { AuthStatus } from "../model/usuarios.model";

export const privateGuard: CanActivateFn = (route, state) => {
  const usuarioService = inject(UsuarioService);
  const router = inject(Router);

  if (usuarioService.authStatus() === AuthStatus.authenticated) {
    //console.log({ privateGuard: state.url });

    return true;
  }

  

  // if(usuarioService.authStatus() === AuthStatus.checking){
  //   return false;
  // }

  router.navigate(["auth/login"]);
  //console.log({ isAuthenticatedGuard: state.url });

  return false;
};
