import { NgModule } from "@angular/core";
import { RouterModule, Routes, PreloadAllModules } from "@angular/router";
import { LoginComponent } from "./auth/login/login.component";
import { ContentLayoutComponent } from "./shared/components/layout/content-layout/content-layout.component";
import { FullLayoutComponent } from "./shared/components/layout/full-layout/full-layout.component";
import { content } from "./shared/routes/content-routes";
import { full } from "./shared/routes/full.routes";
import { privateGuard } from "./shared/guard/privated.guard";
import { publicGuard } from "./shared/guard/public.guard";
import { navUrlGuard } from "./shared/guard/nav-url.guard";
import { InventarioComponent } from "./components/almacen/inventario/inventario.component";
import { AdminGuard } from "./shared/guard/admin.guard";
import { setUrlGuard } from "./shared/guard/set-url.guard";

const routes: Routes = [
  {
    path: "",
    redirectTo: "/ventas/estatus",
    pathMatch: "full",
  },
  {
    path: "auth/login",
    canActivate: [publicGuard],
    component: LoginComponent,
  },
  {
    path: "",
    component: ContentLayoutComponent,
    canActivate: [privateGuard],
    children: content,
    canActivateChild: [setUrlGuard],
  },
  {
    path: "",
    component: FullLayoutComponent,
    canActivate: [privateGuard],
    children: full,
  },
  {
    path: "**",
    redirectTo: "auth/login",
  },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
      preloadingStrategy: PreloadAllModules,
      anchorScrolling: "enabled",
      scrollPositionRestoration: "enabled",
      useHash: true,
    }),
  ],
  exports: [RouterModule],
})
export class AppRoutingModule {}
