import { Sucursales } from "./sucursales.model";

export interface User {
  usuario: string;
  password: string;
}

export interface UserAuthResponse {
  token: string;
  logged: boolean;
}

export interface UserToken {
  id: string;
  usuario: string;
  sucursales: Sucursales[];
}

export interface TokenChekResponse {
  token: string;
  logged: boolean;
}

export enum AuthStatus {
  checking = "checking",
  authenticated = "authenticated",
  notAuthenticated = "notAuthenticated",
}
