import { HttpEvent,HttpHandler,HttpInterceptor,HttpRequest, HttpResponse} from "@angular/common/http";
import { Injectable, inject } from "@angular/core";
import { Observable, catchError, map, of, tap, throwError } from 'rxjs';
import { NotificationService } from "../shared/services/notification.service";

@Injectable({
  providedIn: "root",
})
export class TokenInterceptorService implements HttpInterceptor {
  //private notificationServ = inject(NotificationService);

  constructor(private notificationServ: NotificationService) {}

  intercept(
    req: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    const token = localStorage.getItem("token");
    //console.log("Interceptor:", req);

    if (token) {
      req = req.clone({
        setHeaders: { Authorization: `Bearer ${token}` },
      });
    }

    return next.handle(req).pipe(
      catchError((err) => {
        //console.log("error interceptor:", err);
        this.notificationServ.showError(err); //se envia el error al servicio de errores para que lo muestre
        //return new Observable<HttpEvent<any>>(); //se regresa un observable vacio
        //return new Observable<HttpEvent<null>>();
        //console.log("httpAny", {} as HttpEvent<any>);

        //const emptyResponse = new HttpResponse({ body: {} }); // Crea una respuesta HTTP vacía
        return throwError(()=> err); //se regresa un observable vacio})
        //return of(null);
      })
    );
  }
}
