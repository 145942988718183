import { Component, computed, effect, inject } from "@angular/core";
import { UsuarioService } from "./services/usuario.service";
import { AuthStatus } from "./shared/model/usuarios.model";
import { Router } from "@angular/router";

@Component({
  selector: "app-root",
  templateUrl: "./app.component.html",
  styleUrls: ["./app.component.scss"],
})
export class AppComponent {
  title = "argo";

  private usuarioService = inject(UsuarioService);
  private router = inject(Router);

  public finishedAuthCheck = computed<boolean>(() => {
    if (this.usuarioService.authStatus() === AuthStatus.checking) {
      return false;
    }
    return true;
  });

  public authStatusChangedEffect = effect(() => {
    switch (this.usuarioService.authStatus()) {
      case AuthStatus.checking:
        return;

      case AuthStatus.authenticated:
        const url_local = localStorage.getItem("url")

        if(url_local){
          this.router.navigate([url_local]);
        }
        else{
          this.router.navigate(["ventas/estatus"]);
        }
        return;

      case AuthStatus.notAuthenticated:
        this.router.navigate(["auth/login"]);
        return;
    }
  });
}
