import { Component, Input, OnInit, inject } from '@angular/core';
import { Firma, FirmaResponse } from '../../model/firma.model';
import { NgbActiveModal, NgbModalConfig } from '@ng-bootstrap/ng-bootstrap';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { FirmaService } from 'src/app/services/firma.service';
import { ToastrService } from "ngx-toastr";

@Component({
  selector: "app-firma",
  templateUrl: "./firma.component.html",
  styleUrls: ["./firma.component.scss"],
})
export class FirmaComponent implements OnInit {

  public activeModal = inject(NgbActiveModal);
  private fb = inject(FormBuilder);
  private firmaService = inject(FirmaService);
  private toastrService = inject(ToastrService);

  public firmaForm: FormGroup;
  @Input() firma: Firma;
  @Input() doc: string;
  alerts: FirmaResponse[] = [];
  respuesta;

  constructor(config: NgbModalConfig) {
    // customize default values of modals used by this component tree
    config.backdrop = "static"; // no se permite cerrar al dar click fuera del modal
    config.keyboard = false;
  }

  print_firma() {
    //console.log(this.firma);
  }

  ngOnInit() {
    //console.log("modal.firma:", this.firma);
    this.firmaForm = this.fb.group({
      vta: [null, [Validators.required]],
      cxc: [null, [Validators.required]],
      comentario: [""],
    });
  }

  firmar(estado: boolean): void {
    const comentario = this.firmaForm.controls["comentario"].value;

    this.firma.firmas.splice(0); // se limpian las firmas antes de crear nuevamente

    if (this.firmaForm.controls["vta"].value == true) {
      //si se palomea el check
      this.add_to_firma(estado, 1, comentario);
    }

    if (this.firmaForm.controls["cxc"].value == true) {
      this.add_to_firma(estado, 2, comentario);
    }

    if (this.firma.firmas.length == 0) {
      this.toastrService.error("Necesitas seleccionar al menos un tipo de firma", 'Error', {
        positionClass: 'toast-bottom-right',
      });
    
    } else {
      this.post_firma();
    }
  }

  post_firma() {
    this.firmaService.FirmaPedidoFactura(this.firma).subscribe((resp) => {
      //console.log("post_firma", resp);
      this.respuesta = resp;
      this.activeModal.close(resp);
      //this.alertas_firmas(resp);
    });
  }

  alertas_firmas(resp) {
    for (let index = 0; index < resp.length; index++) {
      //console.log("index", resp[index]);

      this.alerts.push(resp[index]);
    }
  }

  add_to_firma(estado: boolean, idTipoFirma: number, comentario: string) {
    this.firma.firmas.push({
      idTipoFirma: idTipoFirma,
      estado: estado,
      comentario: comentario,
    });
  }

  // close(alert) {
  //   this.alerts.splice(this.alerts.indexOf(alert), 1);
  // }
}
