import { Routes } from '@angular/router';

export const content: Routes = [
  {
    path: "ventas",
    loadChildren: () =>
      import("../../components/ventas/ventas.module").then(
        (m) => m.VentasModule
      ),
    data: {
      breadcrumb: "Ventas",
    },
  },
  {
    path: "almacen",
    loadChildren: () =>
      import("../../components/almacen/almacen.module").then(
        (m) => m.AlmacenModule
      ),
    data: {
      breadcrumb: "Almacen",
    },
  },
];
