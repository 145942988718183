import { Component, OnInit } from "@angular/core";
import { Router } from "@angular/router";
import {
  FormBuilder,
  Validators,
  FormGroup,
} from "@angular/forms";
import { UsuarioService } from "../../services/usuario.service";
import { User } from "src/app/shared/model/usuarios.model";


@Component({
  selector: "app-login",
  templateUrl: "./login.component.html",
  styleUrls: ["./login.component.scss"],
})
export class LoginComponent implements OnInit {
  public loginForm: FormGroup;

  public errorMessage: any;

  constructor(
    private fb: FormBuilder,
    public router: Router,
    private usuarioService: UsuarioService
  ) {}

  ngOnInit() {
    this.loginForm = this.fb.group({
      usuario: ["", [Validators.required, Validators.minLength(3)]],
      password: ["", [Validators.required, Validators.minLength(3)]],
    });
  }

  login(): void {
    const us: User = this.loginForm.value;

    this.usuarioService.login(us).subscribe({
      next: () => this.router.navigate(["ventas/estatus"]),
      error: (err) => {
        //console.log(err);
      },
    });
  }
}
