import { inject } from "@angular/core";
import { CanActivateFn, Router } from "@angular/router";
import { UsuarioService } from "src/app/services/usuario.service";
import { AuthStatus } from "../model/usuarios.model";

export const publicGuard: CanActivateFn = (route, state) => {
  const usuarioService = inject(UsuarioService);
  const router = inject(Router);

  if (usuarioService.authStatus() === AuthStatus.authenticated) {
    //console.log("pasa por el publicGuard");

    router.navigate(["ventas/estatus"]);
    return false;
  }

  return true;
};
