import { Injectable, inject } from "@angular/core";
import {
  ActivatedRouteSnapshot,
  RouterStateSnapshot,
  Router,
} from "@angular/router";
import { Observable } from "rxjs";
import { UsuarioService } from "src/app/services/usuario.service";
import { AuthStatus } from "../model/usuarios.model";

@Injectable({
  providedIn: "root",
})
export class AdminGuard {
  private router = inject(Router);
  private usuarioService = inject(UsuarioService);

  constructor() {}
  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean> | Promise<boolean> | boolean {
    // Guard for user is login or not

    const url = state.url;
    localStorage.setItem('url',url);
    //console.log('url:'+ url);
    
    return true;
  }
}
