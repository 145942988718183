<div class="modal-header">
    <h4 class="modal-title">Firma {{doc}} id: {{firma.idDoc}}</h4>
    <button type="button" class="btn-close" aria-label="Close" (click)="activeModal.dismiss()"></button>
</div>
<div class="modal-body">
    <form [formGroup]="firmaForm">
        <div class="row">
            <div class="col-xl-6 col-sm-12">
                <div class="btn-group btn-option" data-bs-toggle="buttons">
                    <div class="btn btn-outline-primary">
                        <div class="checkbox checkbox-primary">
                            <input formControlName="vta" id="checkbox-primary-1" type="checkbox">
                            <label for="checkbox-primary-1">Vta</label>
                        </div>
                    </div>
                    <div class="btn btn-outline-primary">
                        <div class="checkbox checkbox-primary">
                            <input formControlName="cxc"  id="checkbox-primary-2" type="checkbox">
                            <label for="checkbox-primary-2">Cxc</label>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <!-- <div class="row">
            <div class="col">
                <div class="form-group mb-0 mt-3">
                    <input formControlName="password" class="form-control" type="password" name="pass" id="password" placeholder="password">
                </div>
            </div>
        </div> -->
        <div class="row">
            <div class="col">
                <div class="form-group mb-0 mt-3">
                    <textarea formControlName="comentario" class="form-control" id="exampleFormControlTextarea4" rows="3"
                        placeholder="Comentario"></textarea>
                </div>
            </div>
        </div>
</form>

</div>
<div class="modal-footer">
    <div class="col">
        <button type="button" class="btn btn-outline-success" 
        (click)="firmar(true)">Aceptado</button>
    </div>
    <div class="col">
        <button type="button" class="btn btn-outline-danger" 
        (click)="firmar(false)">Rechazado</button>
    </div>
</div>


