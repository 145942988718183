import { LowerCasePipe } from "@angular/common";
import { HttpErrorResponse} from "@angular/common/http";
import { Injectable } from '@angular/core';
import { throwError, Observable } from 'rxjs';
import Swal from "sweetalert2";

@Injectable({
  providedIn: "root",
})
export class NotificationService {
  private Toast = Swal.mixin({
    toast: true,
    //color:"#fff",
    //background: "#F4A36B",
    position: "center",
    showConfirmButton: false,
    timer: 2000,
    timerProgressBar: true,
    didOpen: (toast) => {
      toast.onmouseenter = Swal.stopTimer;
      toast.onmouseleave = Swal.resumeTimer;
    },
  });

  constructor() {}

  showError(error: HttpErrorResponse) {
    if (error.status === 401) {
      Swal.fire(
        "Sesion expirada",
        "Su sesion ha terminado. /tRefresque el explorador o vuelva a loggearse ",
        "error"
      );
    } else {
      this.Toast.fire({
        icon: "warning",
        text: error.error.mensaje,
      });
    }
    //return throwError(error);
  }

  showSuccess(mensaje: string) {
      this.Toast.fire({
        icon: "success",
        text: mensaje,
      });
  }
  
  showWarning(mensaje: string) {
      this.Toast.fire({
        icon: "warning",
        text: mensaje,
      });
  }

  async confirmation(accion: string): Promise<boolean> {
    let texto: string = "";
    let titulo: string = "";

    switch (accion) {
      case "update": {
        texto = "¿Está seguro de modificar el registro?";
        titulo = "Modificar";
        break;
      }
      case "delete": {
        texto = "¿Está seguro de eliminar el registro?";
        titulo = "Eliminar";
        break;
      }
      case "new": {
        texto =
          "EL codigo no está dentro del inventario. ¿Está seguro de crear el registro?";
        titulo = "Insertar nuevo Codigo";
        break;
      }
    }

    const result = await Swal.fire({
      title: titulo,
      text: texto,
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#F4A36B",
      cancelButtonColor: "#d33",
      confirmButtonText: `Si, ${titulo}!`,
      cancelButtonText: "Cancelar",
    });
    return result.isConfirmed;
  }
}
