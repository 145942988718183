import { Injectable, inject } from '@angular/core';
import { HttpClient } from "@angular/common/http";
import { environment } from 'src/environments/environment';
import { Agente, Catalogo, Comentario, FormaPago, Moneda, UsoCfdi, MetodoPago } from '../shared/model/catalogo.model';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { Ciudad, Direccion, Direcciones, Estado, Paqueteria } from '../shared/model/estatus.model';
import { Sucursales } from '../shared/model/sucursales.model';

@Injectable({
  providedIn: "root",
})
export class CatalogoService {
  private http = inject(HttpClient);
  
  private idSucSource = new BehaviorSubject<number>(0);
  currentIdSuc = this.idSucSource.asObservable();

  public lst_tipoFactura = [
    { tipoFactura: "Normal" },
    { tipoFactura: "Refacturacion" },
    { tipoFactura: "Anticipo" }
  ];

  public lst_tipoVenta = [
    { tipoVenta: "Normal" },
    { tipoVenta: "Consignacion con REP"},
    { tipoVenta: "Consignacion sin REP"},
    { tipoVenta: "Transfer"}
  ];

  public lst_metodoPago: MetodoPago[] = [
    { idMetodoPago: "PUE", metodo: "Pago en una sola exhibicion" },
    { idMetodoPago: "PPD", metodo: "Pago en parcialidades o diferido"}
  ];

  constructor() {}

  getMarcas(): Observable<Catalogo[]> {
    const url = `${environment.url}catalogo/GetMarcas`;
    return this.http.get<Catalogo[]>(url);
  }

  getClases():Observable<Catalogo[]> {
    const url = `${environment.url}catalogo/GetClases`;
    return this.http.get<Catalogo[]>(url)
  }

  GetUsoCfdi():Observable<UsoCfdi[]> {
    const url = `${environment.url}catalogo/GetUsoCfdi`;
    return this.http.get<UsoCfdi[]>(url)
  }

  GetCiudades(idEstado: number): Observable<Ciudad[]> {
    const url = `${environment.url}catalogo/GetEstadosCiudades?IdEstado=${idEstado}`;
    return this.http.get<Ciudad[]>(url);
  }

  GetEstados():Observable<Estado[]> {
    const url = `${environment.url}catalogo/GetEstados`;
    return this.http.get<Estado[]>(url)
  }

  GetFormasPago():Observable<FormaPago[]> {
    const url = `${environment.url}catalogo/GetFormasPago`;
    return this.http.get<FormaPago[]>(url)
  }

  GetMonedas():Observable<Moneda[]> {
    const url = `${environment.url}catalogo/GetMonedas`;
    return this.http.get<Moneda[]>(url)
  }

  GetDiasCredito():Observable<number[]> {
    const url = `${environment.url}catalogo/GetDiasCredito`;
    return this.http.get<number[]>(url)
  }

  GetAgentesActivos(idSuc: number): Observable<Agente[] | []> {
    const url = `${environment.url}catalogo/GetAgentesActivos?IdSuc=${idSuc}`;
    return this.http.get<Agente[]>(url)
  }
  
  GetComentariosPedidos(): Observable<Comentario[] | []> {
    const url = `${environment.url}catalogo/GetComentariosPedidos`;
    return this.http.get<Comentario[]>(url)
  }

  GetPaqueteriasActivas(): Observable<Paqueteria[] | []> {
    const url = `${environment.url}catalogo/GetPaqueteriasActivas`;
    return this.http.get<Paqueteria[]>(url)
  }

  GetDirecciones(idCliente: number): Observable<Direcciones[] | null> {
    const url = `${environment.url}direcciones/GetDirecciones?IdCliente=${idCliente}`;
    return this.http.get<Direcciones[]>(url)
  }

  GetDireccionId(idDireccion: number): Observable<Direccion | null> {
    const url = `${environment.url}direcciones/GetDireccion?IdDireccion=${idDireccion}`;
    return this.http.get<Direccion>(url)
  }

  GetDireccionFiscal(idCliente: number): Observable<Direccion | null> {
    const url = `${environment.url}direcciones/GetDireccionFiscal?IdCliente=${idCliente}`;
    return this.http.get<Direccion>(url)
  }

  GetSucursal(idSuc: number): Observable<Sucursales> {
    const url = `${environment.url}catalogo/GetSucursal?IdSuc=${idSuc}`;
    return this.http.get<Sucursales>(url)
  }

  changeIdSuc(idSuc: number) {
    this.idSucSource.next(idSuc);
  }

 
  private hideSidebarSubject = new Subject<void>();

  hideSidebar$ = this.hideSidebarSubject.asObservable();

  hideSidebar() {
    this.hideSidebarSubject.next();
  }
}