import { Injectable, inject } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { Observable, catchError, map, of, tap } from 'rxjs';
import { Firma, FirmaResponse } from '../shared/model/firma.model';

@Injectable({
  providedIn: "root",
})
export class FirmaService {
  private http = inject(HttpClient);
  
  constructor() {}

  FirmaPedidoFactura(firma: Firma): Observable<FirmaResponse[]> {
    const url = `${environment.url}firmas/FirmaPedidoFactura`;
    return this.http
      .post<Firma>(url, firma)
      .pipe(catchError((err) => of(err.error)));
  }
}
