import { Injectable, HostListener } from '@angular/core';
import { BehaviorSubject } from 'rxjs';


// Menu
export interface Menu {
	path?: string;
	title?: string;
	icon?: string;
	type?: string;
	badgeType?: string;
	badgeValue?: string;
	active?: boolean;
	bookmark?: boolean;
	children?: Menu[];
}

@Injectable({
  providedIn: "root",
})
export class NavService {
  public screenWidth: any;
  public collapseSidebar: boolean = false;
  public fullScreen = false;

  constructor() {
    this.onResize();
    if (this.screenWidth < 991) {
      this.collapseSidebar = true;
    }
  }

  // Windows width
  @HostListener("window:resize", ["$event"])
  onResize(event?: any) {
    this.screenWidth = window.innerWidth;
  }

  MENUITEMS: Menu[] = [
    {
      title: "Ventas",
      icon: "dollar-sign",
      type: "sub",
      badgeType: "primary",
      badgeValue: "new",
      active: true,
      children: [
        { path: "/ventas/estatus", title: "Estatus", type: "link" },
        { path: "/ventas/pedido", title: "Pedido", type: "link" },
        { path: "/ventas/cotizacion", title: "Cotizacion", type: "link" },
      ],
    },
    {
      title: "Almacen",
      icon: "inbox",
      type: "sub",
      badgeType: "primary",
      badgeValue: "new",
      active: true,
      children: [
        { path: "/almacen/inventario", title: "Captura de Inventario", type: "link" },
      ],
    }
  ];
  // Array
  items = new BehaviorSubject<Menu[]>(this.MENUITEMS);
}
